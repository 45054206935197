@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Roboto Condensed Medium";   /*Can be any text*/
  src: local("Roboto-Medium"),
    url("./assets/fonts/Roboto-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Arial";   /*Can be any text*/
  src: local("Arial"),
  url('https://fonts.cdnfonts.com/css/arial') format("truetype");
}

::-moz-selection {
  background:#3297FD;
  color: white;
}
::-webkit-selection {
  background:#3297FD;
  color: white;
}
::selection {
  background:#3297FD;
  color: white;
}

.condMedium {
  font-family: 'Roboto Condensed Medium';
}

.arial {
  font-family: 'Arial';
}

.HighlightFeedGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(646px, 1px));
  grid-gap: 40px 18px;
}

.trendGridBig {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(808, 1px));
  grid-gap: 40px 18px;
}

.newFeedGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(422px, 1px));
  grid-gap: 40px 22px;
}

.trendGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(311px, 1px));
  grid-gap: 40px 22px;
}

.mobileFeedGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(358px, 1px));
  grid-gap: 16px;
}

.trendPostsCardWrapper {
  overflow: hidden;
}

.trendPostsCardWrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: 0 0;
}

.scrollBar::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: 0 0;
}

.calculated-width {
  width: -webkit-calc(100% - 10px);
  width:    -moz-calc(100% - 10px);
  width:         calc(100% - 10px);
}

.truncate-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.truncate-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.truncate-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.truncate-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.button {
  height: 36px;
  border-radius: 8px;
  padding: 9px 24px;
  transition: all;
  transition-duration: 100s;
  display: inline-flex;
  align-items: center;
  font-size: 15px;
  justify-content: center;
  border-radius: 8px;
  font-weight: 700;
}

* {
  animation-duration: 0s !important;
}

.navBarPostHeaderCardGradient {
  background: transparent linear-gradient(180deg, #11111100 0%, #111111cc 100%) 0 0 no-repeat padding-box;
}

.storyLinearTop {
  background: transparent linear-gradient(180deg, #00000067 0%, #00000000 100%) 0% 0% no-repeat;
  opacity: 20;
}

.storyBackgroundLinear {
  background: transparent linear-gradient(214deg, #F53757 0%, #FF9E5D 100%) 0% 0% no-repeat;
  opacity: 100;
}

.storyLinearBig {
  background: transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat padding-box;
}

.storyLinearItem {
  background: transparent linear-gradient(180deg, #00000000 0%, #000000C9 100%) 0% 0% no-repeat padding-box;
  width: 100%;
}

.storyLinearBorder {
  background: transparent linear-gradient(214deg, #f53757 0%, #ff9e5d 100%) 0% 0% no-repeat padding-box;
}

.highlightLinear {
  background: transparent linear-gradient(180deg, #11111100 0%, #11111199 100%) 0% 0% no-repeat padding-box;
}

.navbarLinear {
  background:  transparent linear-gradient(180deg, #1111119A 0%, #00000000 100%) 0% 0% no-repeat padding-box;;
}

.caak-main-wrapper {
  max-width: 1920px;
  margin-right: auto;
  margin-left: auto;
}

.spinner {
  width: max-content;
  text-align: center;
}

.wrapper {
  display: flex;
  overflow-y: auto;
}

.mobileWrapper {
  display: flex;
  flex-wrap: wrap;
}

.wrapper::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.ant-list-item-meta-title{
  font-size: 15px;
  color: #111111;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.spinner > div {
  width: 9px;
  height: 9px;
  margin-left: 2px;
  margin-right: 2px;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.search_modal {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  z-index: 5;
  overflow: auto;
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  justify-content: center;
  display: flex;
}

.ant-btn {
  box-shadow: none;
  text-shadow: none;
  border-radius: 4px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  text-shadow: none;
}

.ant-btn-primary {
  box-shadow: none;
  text-shadow: none;
  border-radius: 4px;
}


.popup_modal {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  z-index: 5;
  overflow: auto;
  position: fixed;
  background: rgba(0, 0, 0, 1);
  justify-content: center;
  display: flex;
  align-items: center;
}

.popup_modal-content {
  min-width: 320px;
  background: #fff;
  max-width: 900px;
  position: relative;
}

.modal-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.emoji {
  width: 30px;
  height: 30px;
}

.fade-in-banner {
  animation: fadeIn 2s;
  -webkit-animation: fadeIn 2s;
  -moz-animation: fadeIn 2s;
  -o-animation: fadeIn 2s;
  -ms-animation: fadeIn 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ant-tabs > .ant-tabs-nav { /* So that there is no gap between the content and tabs */
  margin-bottom: 0;
}

.ant-tabs-top > .ant-tabs-nav::before { 
  border: none;
}

.ant-popover-buttons {
  display: flex;
}

.ant-comment-content-author{
  display: flex;
  flex-direction: column;
}

.ant-notification-notice {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ant-notification-notice-close-icon {
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ant-notification-notice-message {
  color: #fff;
}

.ant-switch-checked {
  background-color: #3B4491;
}

.zoom {
  transition: transform .2s; /* Animation */
  width: 172px;
  height: 100px;
}

.zoom:hover {
  transform: scale(0.95);
}

.zoomIn {
  display: inline-block; /* shrink wrap to image */
  overflow: hidden;
}

.zoomIn img {
  display: block; /* no whitespace */
  transition: 0.4s ease-in-out;
}

.zoomIn:hover img {
  transform: scale(1.05);
}

.reportModalItemsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(238px, 1fr));
}
@media (max-width: 480px) {
  .reportModalItem {
    border-right: 0;
  }
}

/*test*/
.ant-statistic{
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.ant-statistic-title{
  margin-bottom: 0;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  color: #555555;
  margin-left: 3px;
}

.ant-statistic-content{
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  color: #111111;
  font-weight: 500;
}

.w-full .ant-upload {
  width: 100%;
}

.ant-collapse-header-text {
  width: 100%;
}

.ant-collapse-header {
  width: 100%;
}

.custom .ant-collapse-content-box {
  padding: 0;
  padding-top: 0;
}

.ant-notification-notice-with-icon {
  display: flex;
  flex-direction: column;
}
.ant-list-item {
  display: flex;
  flex-direction: row;
}

.top-to-btm{
  position: relative;
}

.icon-position{
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20;
}

.icon-style{
  background-color: rgb(255,255,255,0.9);
  border: 1px solid #00000010;
  border-radius: 100%;
  height: 40px;
  width: 40px;
  color: #000;
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  animation: movebtn 3s ease-in-out infinite;
  transition: all .5s ease-in-out;
}

.icon-style:hover{
  animation: none;
  background: #fff;
  color: #551B54;
  border: 2px solid #551B54;
}

@keyframes movebtn {
  0%{
    transform: translateY(0px);
  }
  25%{
    transform: translateY(20px);
  }
  50%{
    transform: translateY(0px);
  }
  75%{
    transform: translateY(-20px);
  }
  100%{
    transform: translateY(0px);
  }
}

.fade-in-banner {
  animation: fadeIn 2s;
  -webkit-animation: fadeIn 2s;
  -moz-animation: fadeIn 2s;
  -o-animation: fadeIn 2s;
  -ms-animation: fadeIn 2s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane, .ant-tabs-left > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding-left: 60px;
}

.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-right-color: #EFEEEF;
}
.ant-tabs-left > .ant-tabs-content-holder, .ant-tabs-left > div > .ant-tabs-content-holder {
  border-left: 0;
}

.padding_zero .ant-popover-inner-content { 
  padding: 0;
}

.ck-content p a {
  color: var(--ant-primary-color);
}

.element.style {
  z-index: 5;
}

.story-linear {
  background: transparent linear-gradient(180deg, #00000000 0%, #000000B2 100%) 0% 0% no-repeat padding-box;;
}

.ant-radio-checked .ant-radio-inner{
  border-color: #3B4491 !important ;
}

.ant-radio-checked .ant-radio-inner:after{
  background-color: #3B4491;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #3B4491 ;
}

.ant-form-item-control-input {
  min-height: 22px;
}

@media only screen and (min-width: 600px) {

  .box {
    display: block;
    transition: 0.2s;
    transition-timing-function: ease-out;
  }

  .box:hover {
    transform: translate(0px, -10px);
  }

}

.ant-modal-header {
  border-bottom: 1px solid #D4D8D8;
}

.ant-modal-content {
  border-radius: 6px;
}

.ant-btn:not(.ant-btn-round) {
  border-radius: 4px;
}

.ant-input {
  border-radius: 4px;
}

.text-container {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.see-more {
  display: none;
}

.square-list {
  list-style: none;
  padding-left: 0;
}

.square-list li:before {
  content: '▪';
  margin-right: 0.5em;
}