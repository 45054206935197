@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Literata:opsz,wght@7..72,200;7..72,300;7..72,400;7..72,500;7..72,600;7..72,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'icon-fonts.css';
@import 'buttons.css';
@import 'dropdown.css';
@import 'add.scss';
@import '~antd/dist/antd.variable.css';

.ant-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-btn:not(.ant-btn-round) {
  border-radius: 3px;
}

.ant-btn .anticon {
  margin: 0;
}

.ant-btn-primary {
  background-color: var(--ant-primary-color) !important;
}

.ant-modal-footer{
  display: flex;
  justify-content: right;
}

.caak-saved-articles .ant-list-item{
  padding: 4px 8px;
}
.caak-saved-articles .ant-list-item-action{
  margin: 0;
}

@media only screen and (min-width: 600px) {
  .transparent-header {
    background: transparent linear-gradient(180deg, #1111119A 0%, #00000000 100%) 0% 0% no-repeat padding-box;
  }
}

@media only screen and (max-width: 599px) {
  .transparent-header {
    background-color: white;
  }
}

.caak-menu .ant-collapse-header {
  padding: 0 !important;
}

.ant-message .ant-message-custom-content{
  display: flex;
  align-items: center;
}

.post-view-block a {
  color: var(--ant-primary-color);
}